import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { font, color, device } from "../../layout/global"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const FeatureLine = ({
  id,
  _title,
  text,
  titleFontSize,
  titleWidth,
  textWidth,
  marginLeft,
  positionCenter,
  image,
  image2,
  whiteBg,
  twoImages,
  midBrightness,
  growUp,
  noImage,
  alignLeft,
  height,
  multipleLines,
  paddingTop,
  noBar,
  blue,
}) => {
  const ref = useRef(null),
    imageRef = useRef(null),
    image2Ref = useRef(null),
    titleRef = useRef(null),
    textRef = useRef(null),
    lineRef = useRef(null)

  // ANIMAÇÕES
  useEffect(() => {
    if (typeof window !== "undefined" && window.matchMedia(device.tabletP).matches) {
      let trigger = ref.current

      // ANIMAÇÃO 1
      // Sem imagem
      if (noImage) {
        gsap
          .timeline({
            paused: true,
            scrollTrigger: {
              trigger: trigger,
              start: "30% center",
            },
          })
          .fromTo(
            [titleRef.current, lineRef.current, textRef.current],
            { opacity: 0, y: 25 },
            { opacity: 1, y: 0, duration: 0.5, stagger: 0.25 }
          )
      }

      // ANIMAÇÃO 2
      // Só com uma imagem
      if (!twoImages && !growUp && !noImage) {
        let tl1 = gsap.timeline({
          id: "feature-line-image",
          paused: true,
          scrollTrigger: {
            trigger: trigger,
            start: "30% center",
          },
        })
        // Sem filtro de opacidade
        if (!midBrightness) {
          tl1.fromTo(imageRef.current, { opacity: 0 }, { opacity: 1, duration: 0.3 })
          tl1.fromTo(
            [titleRef.current, lineRef.current, textRef.current],
            { opacity: 0, y: 25 },
            { opacity: 1, y: 0, duration: 0.3, stagger: 0.15 }
          )
        }
        // Com filtro de opacidade
        else {
          tl1.fromTo(imageRef.current, { opacity: 0, y: 25 }, { opacity: 0.5, y: 0, duration: 0.3 })
          tl1.fromTo(
            [titleRef.current, lineRef.current, textRef.current],
            { opacity: 0, y: 25 },
            { opacity: 1, y: 0, duration: 0.3, stagger: 0.15 }
          )
        }
        // Com fundo branco -> preto
        if (whiteBg) {
          tl1.to(trigger, { background: "black", duration: 1 })
        }
      }

      // ANIMAÇÃO 3
      // Com duas imagens
      if (twoImages && !noImage) {
        gsap
          .timeline({
            id: "feature-line-image",
            paused: true,
            scrollTrigger: {
              trigger: trigger,
              start: "30% center",
            },
          })
          .fromTo(imageRef.current, { opacity: 0 }, { opacity: 1, duration: 0.3 })
          .fromTo(
            [titleRef.current, lineRef.current, textRef.current],
            { opacity: 0, y: 25 },
            { opacity: 1, y: 0, duration: 0.3, stagger: 0.3 }
          )
          .to(imageRef.current, { opacity: 0, duration: 1.5 }, "<+0.3")
          .fromTo(image2Ref.current, { opacity: 0 }, { opacity: 0.7, duration: 1.5 }, "<")
      }

      // ANIMAÇÃO 4
      // Grow Up - Electram
      if (growUp && !noImage) {
        gsap
          .timeline({
            id: "feature-line-image",
            paused: true,
            scrollTrigger: {
              trigger: trigger,
              start: "30% center",
            },
          })
          .fromTo(imageRef.current, { opacity: 0, width: "40%" }, { opacity: 0.2, duration: 0.5 })
          .to(imageRef.current, { width: "100%", duration: 1 }, "<")
          .fromTo(
            [titleRef.current, lineRef.current, textRef.current],
            { opacity: 0, y: 25 },
            { opacity: 1, y: 0, duration: 0.5, stagger: 0.35 },
            "<+0.5"
          )
      }
    }
  }, [twoImages, midBrightness, whiteBg, growUp, noImage])
  // ANIMAÇÕES - FIM

  return (
    <Wrapper
      titleWidth={titleWidth}
      textWidth={textWidth}
      marginLeft={marginLeft}
      positionCenter={positionCenter}
      whiteBg={whiteBg}
      id={id}
      ref={ref}
      alignLeft={alignLeft}
      height={height}
      paddingTop={paddingTop}
      noBar={noBar}
      blue={blue}
      titleFontSize={titleFontSize}
    >
      <div className="feat-image" ref={imageRef}>
        {image && (
          <img
            src={image}
            alt="microram"
            style={{
              objectFit: "contain",
              objectPosition: "center center",
            }}
          />
        )}
      </div>
      {image2 && (
        <div className="feat-image-2" ref={image2Ref}>
          <img
            src={image}
            alt="microram"
            style={{
              objectFit: "contain",
              objectPosition: "center center",
            }}
          />
        </div>
      )}
      <div className="container">
        <h2 ref={titleRef} dangerouslySetInnerHTML={{ __html: _title }}></h2>
        <span className="text-line" ref={lineRef}></span>
        {multipleLines ? (
          <div>
            {text.map((i, index) => (
              <p key={index}>{i}</p>
            ))}
          </div>
        ) : (
          <p ref={textRef} dangerouslySetInnerHTML={{ __html: text }}></p>
        )}
      </div>
    </Wrapper>
  )
}

export default FeatureLine

const Wrapper = styled.section`
  display: grid;
  position: relative;
  background-color: ${props => (props.whiteBg ? "#fff" : "#000")};
  width: 100%;
  height: ${props => (props.height ? props.height : "100vh")};
  opacity: 1;
  overflow: hidden;
  padding-top: ${props => (props.paddingTop ? props.paddingTop : "0")};
  padding-left: ${props => (props.paddingTop ? "100px" : "0")};
  padding-right: ${props => (props.paddingTop ? "100px" : "0")};

  div {
    opacity: 1;
    justify-self: center;
    align-self: center;
    position: relative;
  }

  .container {
    margin-left: ${props => props.marginLeft && props.marginLeft};
    justify-self: ${props => (props.alignLeft ? "flex-start" : props.paddingTop && "auto")};
  }

  h2 {
    width: ${props => (props.titleWidth ? props.titleWidth : "100%")};
    margin: ${props => props.positionCenter && "0 auto"};
    text-align: ${props => (props.positionCenter ? "center" : "left")};
    position: relative;
    ${font.robotoBold};
    font-size: ${props => (props.titleFontSize ? props.titleFontSize : "6rem")};
    color: ${props => (props.whiteBg ? color.greyDark : color.white)};
    line-height: 1;
    letter-spacing: 1px;
    opacity: 0;
    & span {
      color: ${props => (props.blue ? "#07c7ed" : color.red)};
    }
  }

  .text-line {
    display: ${props => (props.positionCenter || props.noBar ? "none" : "block")};
    width: 60px;
    height: 5px;
    background-color: ${color.white};
    margin-top: 50px;
  }

  p {
    width: ${props => (props.textWidth ? props.textWidth : "500px")};
    margin: ${props => (props.positionCenter ? "50px auto" : "55px 0 0 0")};
    text-align: ${props => (props.positionCenter ? "center" : "left")};
    ${props => (props.whiteBg ? font.robotoMedium : font.roboto400)};
    font-size: 2rem;
    color: ${props => (props.whiteBg ? "#97999b" : color.white)};
    opacity: 0;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }

  .feat-image {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    opacity: 0;
  }
  .feat-image-2 {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
`
