import React from "react"
import styled, { css } from "styled-components"
import { font, color } from "../../layout/global"
import SubmitButton from "../../components/SubmitButton"
import { Link } from "gatsby"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const FeatureLine = ({
  id,
  _title,
  text,
  text1,
  text2,
  homepage,
  titleWidth,
  textWidth,
  marginLeft,
  positionCenter,
  positionCenterTop,
  height,
  image,
  whiteBg,
  midBrightness,
  positionTopBottom,
  absolute,
  absoluteCenter,
  sliderBtn,
  btnText,
  btnLink,
  alignRight,
  opacity,
  blue,
}) => {
  return (
    <Wrapper
      titleWidth={titleWidth}
      textWidth={textWidth}
      homepage={homepage}
      marginLeft={marginLeft}
      positionCenter={positionCenter}
      whiteBg={whiteBg}
      id={id}
      positionTopBottom={positionTopBottom}
      absolute={absolute}
      absoluteCenter={absoluteCenter}
      positionCenterTop={positionCenterTop}
      sliderBtn={sliderBtn}
      btnLink={btnLink}
      btnText={btnText}
      alignRight={alignRight}
      opacity={opacity}
      blue={blue}
      height={height}
    >
      {sliderBtn && (
        <Link className="anchor-btn" to={btnLink}>
          <SubmitButton text={btnText} />
        </Link>
      )}
      <div className="feat-image">
        {image && (
            <img 
                src={image} 
                alt='carousel-mobile' 
                style={{
                    objectFit:'contain', 
                    objectPosition:'center center'
                }}
            />
        )}
      </div>
      <div className="overlay"></div>
      <div className="container">
        <h2 dangerouslySetInnerHTML={{ __html: _title }}></h2>
        <section>
          <p dangerouslySetInnerHTML={{ __html: text1 }}></p>
          <p dangerouslySetInnerHTML={{ __html: text2 }}></p>
        </section>
      </div>
    </Wrapper>
  )
}

export default FeatureLine

const Wrapper = styled.section`
  display: flex;
  flex-direction: ${props =>
    props.positionCenterTop
      ? "column-reverse"
      : props.sliderBtn
      ? "column-reverse"
      : "column"};
  justify-content: center;
  position: relative;
  background-color: ${props => (props.whiteBg ? "#fff" : "#000")};
  width: 100%;
  opacity: 1;
  overflow: hidden;
  padding: 30px 0;
  min-height: ${props => (props.height ? props.height : "100vh")};

  .anchor-btn {
    justify-content: center;
    display: flex;

    > div {
      margin: ${props => (props.alignRight ? "20px 0 0 auto" : "20px 0 0 0")};
    }

    button {
      background-color: rgba(255, 255, 255, 0.1);
      margin-top: 20px;
    }

    p {
      font-size: ${props =>
        props.sliderBtn ? "1rem !important" : "1.4rem !important"};
    }
  }

  div {
    opacity: 1;
    justify-self: center;
    align-self: center;
    position: relative;
  }

  .overlay {
    display: ${props => (props.positionTopBottom ? "block" : "none")};
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  .container {
    margin-left: ${props => props.marginLeft && props.marginLeft};
    width: 100%;
    position: ${props =>
      props.positionTopBottom
        ? "absolute"
        : props.absoluteCenter && "absolute"};
    height: ${props => props.positionTopBottom && "100%"};
    display: ${props => props.positionTopBottom && "flex"};
    flex-direction: ${props => props.positionTopBottom && "column"};
    justify-content: ${props => props.positionTopBottom && "space-between"};
    padding: ${props => (props.positionTopBottom ? "100px 20px" : "0 20px")};
  }

  h2 {
    width: ${props => (props.titleWidth ? props.titleWidth : "100%")};
    margin: ${props => props.positionCenter && "0 auto"};
    text-align: ${props =>
      props.positionCenter
        ? "center"
        : props.absoluteCenter
        ? "center"
        : props.positionCenterTop
        ? "center"
        : props.alignRight
        ? "right"
        : "left"};
    opacity: ${props => (props.opacity ? props.opacity : "1")};
    position: relative;
    ${font.robotoBold};
    font-size: ${props => (props.sliderBtn ? "2.5rem" : "3.7rem")};
    color: ${props => (props.whiteBg ? color.greyDark : color.white)};
    line-height: 1.2;
    letter-spacing: 1px;
    & span {
      color: ${props => (props.blue ? "#07c7ed" : color.red)};
    }
  }
  p {
    width: ${props => (props.textWidth ? props.textWidth : "100%")};
    margin: ${props =>
      props.positionCenter ? "50px auto" : props.positionTopBottom && "0"};
    text-align: ${props =>
      props.positionCenter
        ? "center"
        : props.positionCenterTop
        ? "center"
        : "left"};
    ${props => (props.whiteBg ? font.robotoMedium : font.roboto400)};
    font-size: 1.4rem;
    color: ${props => (props.whiteBg ? "#97999b" : color.white)};
  }

  .gatsby-image-wrapper {
    width: 100%;
  }

  .feat-image {
    position: relative;
    width: 100%;
  }

  ${props =>
    props.homepage &&
    css`
      section {
        margin-top: 25px;
      }
    `}
`
