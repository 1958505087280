import React, { useState, useEffect, useRef } from "react"
import { Carousel } from "react-responsive-carousel"
import styled from "styled-components"
import { color, font, ease, device } from "../../layout/global"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import SubmitButton from "../../components/SubmitButton"
import { Link } from "gatsby"

import LeftArrow from "../../images/Products/left-arrow.svg"
import RightArrow from "../../images/Products/right-arrow.svg"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const CarouselComponent = ({
  showArrows,
  showIndicators,
  transitionTime,
  slideTime,
  autoPlay,
  id,
  className,
  carouselContent
}) => {
  const isMounted = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    let slides = document.querySelectorAll(`#${id} .slide`)
    slides.forEach((e, i) => {
      e.id = `slide_homepage_${i + 1}`
    })
  }, [id])
  // ANIMATIONS
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.matchMedia(device.tabletP).matches
    ) {
      let slides = document.querySelectorAll(`#${id} .slide:not(.selected)`)
      let slidesSelected = document.querySelectorAll(`#${id} .slide.selected`)
      let trigger = ref.current
      let tl = gsap
        .timeline({
          id: "carousel-home",
          paused: true,
          scrollTrigger: {
            trigger: trigger,
            start: "center bottom",
            end: "bottom bottom",
          },
        })
        .fromTo(trigger, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
      slides.forEach(e => {
        let description = e.querySelector(".description")
        let anchor = e.querySelector(".anchor-btn")
        return tl.set([e, anchor, description], { opacity: 0 })
      })
      slidesSelected.forEach(e => {
        let description = e.querySelector(".description")
        let anchor = e.querySelector(".anchor-btn")
        return tl.fromTo(
          [e, anchor, description],
          { opacity: 0 },
          { opacity: 1, duration: 0.3 },
          0
        )
      })
      tl.to({}, {}, ">+2")
    }
  }, [id])

  useEffect(() => {
    setTimeout(() => {
      let slideOff = document.querySelectorAll(`#${id} .slide`)
      slideOff.forEach(e => {
        let desc = e.querySelector(".description")
        let anchor = e.querySelector(".anchor-btn")
        e.style.opacity = 0
        anchor.style.opacity = 0
        desc.style.opacity = 0
      })
      let slide = document.querySelectorAll(`#${id} .slide.selected`)
      slide.forEach(e => {
        let desc = e.querySelector(".description")
        let anchor = e.querySelector(".anchor-btn")
        e.style.opacity = 1
        anchor.style.opacity = 1
        desc.style.opacity = 1
      })
    }, 150)
  }, [currentSlide, id])

  useEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  })

  const Next = () => {
    let slide = document.querySelectorAll(`#${id} .slide.selected`)
    slide.forEach(e => {
      let desc = e.querySelector(".description")
      let anchor = e.querySelector(".anchor-btn")
      e.style.opacity = 0
      anchor.style.opacity = 0
      desc.style.opacity = 0
    })
    setTimeout(function() {
      setCurrentSlide(currentSlide + 1)
    }, 150)
  }

  const Prev = () => {
    let slide = document.querySelectorAll(`#${id} .slide.selected`)
    slide.forEach(e => {
      let desc = e.querySelector(".description")
      let anchor = e.querySelector(".anchor-btn")
      e.style.opacity = 0
      anchor.style.opacity = 0
      desc.style.opacity = 0
    })
    setTimeout(function() {
      setCurrentSlide(currentSlide - 1)
    }, 150)
  }

  const updateCurrentSlide = index => {
    if (isMounted.current) {
      currentSlide !== index && setCurrentSlide(index)
    }
  }

  return (
    <CarouselContainer className="carousel-container" id={id} ref={ref}>
      <LeftArrow onClick={Prev} id="carousel-prev" className="control-arrows" />
      <RightArrow
        onClick={Next}
        id="carousel-next"
        className="control-arrows"
      />
      <Wrapper
        showArrows={showArrows}
        showIndicators={showIndicators}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={autoPlay}
        transitionTime={transitionTime}
        interval={slideTime}
        className={className}
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
      >
        {carouselContent['sliders'].map((i, index) => (
          <div className="container" key={index}>
            {i.image && (
                <img 
                    src={i.image} 
                    alt='home' 
                    style={{
                        objectFit:'contain', 
                        objectPosition:'center center'
                    }}
                />
            )}
            <div className="description">
              <h2
                className="title"
                dangerouslySetInnerHTML={{ __html: i.title }}
              ></h2>
              <span className="text-line"></span>
              <h1
                className="oven-title"
                dangerouslySetInnerHTML={{ __html: i.text }}
              ></h1>
            </div>
            <Link className="anchor-btn" alt={i.button} to={i.link}>
              <SubmitButton text={i.button} hasDarkBackground />
            </Link>
          </div>
        ))}
      </Wrapper>
    </CarouselContainer>
  )
}

export default CarouselComponent

const Wrapper = styled(Carousel)`
  background-color: #000;

  .carousel .slide {
    background: #000;
    transition: all 0.15s ${ease.out};
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: none;
  }
  .container,
  .image {
    height: 100%;
  }
  .container {
    position: relative;
  }
  .description {
    position: absolute;
    top: 35%;
    left: 18%;
    transform: translateY(-40%);
    opacity: 0;
    transition: all 0.5s ${ease.out};
    transition-delay: 0.75s;
  }
  .anchor-btn {
    transition: all 0.5s ${ease.out};
    transition-delay: 0.5s;
  }
  .title {
    color: ${color.white};
    z-index: 1;
    ${font.robotoBold};
    text-align: left;
    font-size: 6rem;
    letter-spacing: 1px;
    line-height: 1;
    width: 100%;
  }
  .text-line {
    display: block;
    width: 60px;
    height: 5px;
    background-color: ${color.white};
    margin-top: 50px;
  }
  .oven-title {
    width: 100%;
    margin: 50px auto;
    text-align: left;
    ${font.roboto400};
    font-size: 2rem;
    color: #ffffff;
  }
  .text {
    margin-top: 20px;
    width: 525px;
    color: ${color.white};
    z-index: 1;
    ${font.robotoRegular};
    font-size: 1.8rem;
    text-align: left;
  }
  span {
    color: ${color.red};
  }
  .image {
    opacity: 0;
    transition: all 0.5s ${ease.out};
    transition-delay: 0.5s;
  }
  .carousel .slide.selected .description {
    transform: translateY(-60%);
    opacity: 1;
  }
  .carousel .slide.selected .image {
    opacity: 1;
  }
  .control-dots .dot.selected {
    background: ${color.red};

    &:hover {
      background: ${color.red};
    }
  }
  .control-dots {
    bottom: 5%;
  }
  .control-dots .dot {
    background: ${color.grey};
    transition: all 0.3s ${ease.out};
    width: 50px;
    height: 3px;
    box-shadow: none;
    border-radius: 0%;
    position: relative;

    &:hover {
      background: ${color.greyDark};
    }

    &:focus {
      outline: 0;
    }
    &::after {
      content: "";
      position: absolute;
      display: block;
      background-color: transparent;
      width: 100%;
      height: 1200%;
      top: 0;
      transform: translateY(-50%);
    }
  }

  .anchor-btn > button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 90px;
    left: 18%;
    color: ${color.white};
    background: #101010;
  }

  @media screen and (max-width: 1000px) {
    .carousel .slide.selected .description {
      transform: translateY(-45%);
    }
  }
`

export const CarouselContainer = styled.div`
  background-color: #000;
  position: relative;

  #carousel-prev {
    left: 0;
    margin-left: 40px;
  }
  #carousel-next {
    right: 0;
    margin-right: 40px;
  }
  .control-arrows {
    position: absolute;
    height: 100%;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
    width: 30px;
    fill: ${color.white};
    transition: all 0.3s ${ease.out};
    padding: 5px;

    &:hover {
      fill: ${color.red};
      cursor: pointer;
    }
  }
`
