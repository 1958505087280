import React, { useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { font, color, device } from "../../layout/global"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const VideoDetail = ({ src, _title, id, positionCenter, titleWidth, textWidth, text1, text2, blue, hasOverlay, notCentered }) => {
  const ref = useRef(null),
    videoRef = useRef(null),
    titleRef = useRef(null),
    pRef = useRef(null),
    lineRef = useRef(null)

  // ANIMATIONS
  useEffect(() => {
    if (typeof window !== "undefined" && window.matchMedia(device.tabletP).matches) {
      let trigger = ref.current

      gsap
        .timeline({
          paused: true,
          scrollTrigger: {
            trigger: trigger,
            start: "top center",
          },
        })
        .set([videoRef.current, titleRef.current, pRef.current, lineRef.curent], { opacity: 0 })
        .to(videoRef.current, { opacity: 0.5, duration: 1 })

      gsap
        .timeline({
          paused: true,
          scrollTrigger: {
            trigger: trigger,
            start: "35% center",
          },
        })
        .fromTo(
          [titleRef.current, lineRef.current, pRef.current],
          { opacity: 0, y: 25 },
          { opacity: 1, y: 0, duration: 0.3, stagger: 0.15 }
        )
    }
  }, [])
  // ---

  return (
    <Wrapper
      className="container"
      positionCenter={positionCenter}
      titleWidth={titleWidth}
      ref={ref}
      text={text1}
      textWidth={textWidth}
      id={id}
      blue={blue}
      hasOverlay={hasOverlay}
      notCentered={notCentered}
    >
      <video className="video" width="100%" autoPlay playsInline muted loop ref={videoRef}>
        <source src={src} type="video/mp4"></source>
      </video>

      <div className="text-block">
        <h1 ref={titleRef} dangerouslySetInnerHTML={{ __html: _title }}></h1>
        {(text1 || text2) && (
          <>
            <span className="text-line" ref={lineRef}></span>
            <span className="p-container">
              {text2 ? (
                <p ref={pRef} dangerouslySetInnerHTML={{ __html: `${text1}<br/>${text2}` }}></p>
              ) : (
                <p ref={pRef} dangerouslySetInnerHTML={{ __html: `${text1}` }}></p>
              )}
            </span>
          </>
        )}
      </div>

      <div className="overlay"></div>
    </Wrapper>
  )
}

export default VideoDetail

const Wrapper = styled.section`
  background-color: #000;
  width: 100%;
  opacity: 1;
  overflow: hidden;
  position:relative;
  line-height: 0;
  
  .video {
    pointer-events: none;
    opacity: 0;
  }

  .overlay{
    position: absolute;
    top:0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    opacity: ${props => (props.hasOverlay ? 1 : 0)};
  }

  .text-block{
    position: absolute;
    top: ${props => (props.notCentered ? "unset" : "50%")};
    bottom: ${props => (props.notCentered ? "5%" : "unset")};
    left: 50%;
    transform: ${props => (props.notCentered ? "translate(-50%, 0)" : "translate(-50%, -50%)")};
    z-index: 1;
    line-height: 1.5;
  }

  .text-line {
    display: ${props => (props.positionCenter ? "none" : "block")};
    width: 60px;
    height: 5px;
    background-color: ${color.white};
    margin-top: 50px;
  }

  h1{
    ${font.robotoBold};
    font-size: 6rem;
    letter-spacing: 1px;
    line-height: 1;
    color:#fff;
    text-align:left;
    width: ${props => props.titleWidth && props.titleWidth};
    opacity: 0;

    & span {
      color: ${props => (props.blue ? "#07c7ed" : color.red)};
    }

    /* &:before{
      content: '';
      display: ${props => (props.positionCenter ? "none" : "block")};
      position: absolute;
      width: 60px;
      height: 5px;
      background-color: ${color.white};
      bottom: ${props => (props.text ? "-60%" : "-80%")};
    } */
  }

  .p-container{
    ${font.roboto400};
    font-size: 2rem;
    color: ${color.grey};
    
    p{
      margin: ${props => props.text && "50px 0 0 0"};
      width: ${props => props.textWidth && props.textWidth};
      opacity: 0;
    }
  }
  

  ${props =>
    props.positionCenter
      ? css`     
      .p-container{
        display:block;
        width: ${props => (props.textWidth ? props.textWidth : "60%")};
        margin: 50px auto;
        text-align:center;

        p{
          margin: 60px 0 0 0
        }
      }

      h1{
        text-align:center;
      }
    }`
      : css``}
`
