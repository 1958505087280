import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import Submit from "../SubmitButton"
import { Link } from "gatsby"
import { font, color, device } from "../../layout/global"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const Introduction = ({ className, id, homepage, configurador, lang, blue, link, br, translation, smallerSpacing }) => {
  const ref = useRef(null)
  const refH1 = useRef(null)
  const refH2 = useRef(null)
  const refP = useRef(null)
  const refSubmit = useRef(null)
  var config = true
  if(lang === 'br' || lang === 'mx' ){
    config = false
  }
  let btnConfigurador

  switch (lang) {
    case "pt":
      btnConfigurador = "Configurador"
      break
    case "en":
      btnConfigurador = "Configurator"
      break
    case "es":
      btnConfigurador = "Configurador"
      break
    case "fr":
      btnConfigurador = "Configurateur"
      break
    case "br":
      btnConfigurador = "Configurador"
      break
    default:
      break
  }

  // ANIMATIONS
  useEffect(() => {
    let trigger = ref.current
    let triggerH1 = refH1.current
    let triggerH2 = refH2.current
    let triggerP = refP.current
    let triggerSubmit = refSubmit.current

    let tl = gsap.timeline({
      id: "introduction",
      paused: true,
      scrollTrigger: {
        trigger: trigger,
        toggleActions: "resume none none none",
        start: "center 80%",
      },
    })
    tl.fromTo(
      [triggerH1, triggerH2, triggerP],
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, ease: "power2.out", duration: 0.3, stagger: 0.15 }
    )
    homepage && tl.fromTo(triggerSubmit, { opacity: 0, y: 20 }, { opacity: 1, y: 0, ease: "power2.out", duration: 0.3 })
    blue && tl.fromTo([triggerH2], { color: "rgba(225, 6, 0, 1)" }, { color: "#07c7ed", duration: 1 })
  }, [homepage, blue])

  return (
    <Wrapper className={className} id={id} homepage={homepage} ref={ref} br={br} smallerSpacing={smallerSpacing}>
      <h1 ref={refH1} dangerouslySetInnerHTML={{ __html: homepage ? translation["bannerTitle"] : translation["title"] }}></h1>
      <h2
        ref={refH2}
        dangerouslySetInnerHTML={{ __html: homepage ? translation["bannerSubtitle"] : translation["subtitle"] }}
      ></h2>
      {translation["text"] && <p className="paragraph" ref={refP} dangerouslySetInnerHTML={{ __html: translation["text"] }}></p>}
      {homepage && (
        <Link ref={refSubmit} to={translation["buttonLink"]} className="submit-wrapper">
          <Submit text={translation["button"]} />
        </Link>
      )}
      {config && configurador && (
        <Link ref={refSubmit} to={link} className="submit-wrapper">
          <Submit text={btnConfigurador} />
        </Link>
      )}
    </Wrapper>
  )
}

export default Introduction

const Wrapper = styled.div`
  background-color: white;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media ${device.mobileP} {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 20px;
  }

  @media ${device.tabletP} {
    display: grid;
    align-content: center;
    text-align: center;
    grid-row-gap: ${props => props.homepage && "30px"};
    padding: 0 20px 5vh 20px;
    height: ${props => (props.smallerSpacing ? "unset" : "calc(100vh + 1px)")};
    min-height: ${props => (props.smallerSpacing ? "unset" : "100vh")};
    padding-top: ${props => (props.smallerSpacing ? "25rem" : 0)};
  }

  h1 {
    ${font.robotoBold};
    color: ${color.greyDark};
    letter-spacing: 1px;

    @media ${device.mobileP} {
      font-size: 4rem;
      line-height: 1;
    }

    @media ${device.desktop} {
      font-size: 6rem;
      line-height: ${props => (props.br ? 1 : 1.7)};
      margin-bottom: ${props => (props.br ? `75px` : `0px`)};
    }

    span {
      color: ${color.red};
    }
  }
  h2 {
    ${font.financierLightI};
    color: ${color.red};
    max-width:900px;

    @media ${device.mobileP} {
      font-size: 3.5rem;
      line-height: 40px;
      letter-spacing: 0.1px;
      margin-top: 20px;
    }

    @media ${device.desktop} {
      margin-top: -3rem;
      font-size: 6.5rem;
      line-height: ${props => (props.homepage ? "70px" : "inherit")};
      letter-spacing: -1.5px;
    }
  }
  .paragraph {
    ${font.robotoMedium};
    color: ${color.greyDarker};

    @media ${device.mobileP} {
      font-size: 1.6rem;
      margin-top: 50px;
    }

    @media ${device.desktop} {
      width: 720px;
      font-size: 2rem;
      margin: auto;
      margin-top: 2.5%;
    }
  }

  .submit-wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
`
